import React, { useEffect, useState } from 'react';
import '../App.css'; // Import your CSS file
import Header from '../components/header';
import Footer from '../components/footer';

const DetailsPage = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const storedData = localStorage.getItem('uploadedFileData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            if (parsedData.structuredAiResponse) {
                setData(parsedData);
            } else {
                setError('No structured AI response found.');
            }
        } else {
            setError('No data available in local storage.');
        }
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    const { structuredAiResponse } = data;

    return (
        <div className="details-container">
            <Header />
            <h1>Resume Analysis & Interview Prep</h1>

            <h2 className="personal-details-header">
            <i className="fas fa-address-card"></i>Personal Details
            </h2>
            <div className="personal-details">
                <p><strong>Phone:</strong>  {structuredAiResponse.phone}</p>
                <p><strong>Email:</strong> {structuredAiResponse.email}</p>
                <p><strong>Experience in years:</strong> {structuredAiResponse.experienceInYears}</p>
            </div>

            <h2>
                <i className="fas fa-tools"></i> Skills
            </h2>
            <ul className="skills-list">
                {structuredAiResponse.skills.map((skill, index) => (
                    <li key={index} className="skill-item">
                        <i className="fas fa-check-circle skill-icon"></i>
                        {skill}
                    </li>
                ))}
            </ul>

            <h2>
                <i className="fas fa-chart-line"></i> Career Insights
            </h2>
            <div className="career-insights">
                <p>
                    <i className="fas fa-briefcase"></i> Career Path Prediction:
                </p>
                <ul>
                    <li>
                        {JSON.stringify(structuredAiResponse.carrerInsight.careerPathPrediction).replace(/[{}"]/g, '').replace(':', ': ')}
                    </li>
                </ul>
                
                <p>
                    <i className="fas fa-dollar-sign"></i> Salary Insights:
                </p>
                <ul>
                    <li>
                        {JSON.stringify(structuredAiResponse.carrerInsight.salaryInsights).replace(/[{}"]/g, '').replace(':', ': ')}
                    </li>
                </ul>
                
                <p>
                    <i className="fas fa-line-chart"></i> Job Market Trends:
                </p>
                <ul>
                    <li>
                        {JSON.stringify(structuredAiResponse.carrerInsight.jobMarketTrends).replace(/[{}"]/g, '').replace(':', ': ')}
                    </li>
                </ul>
            </div>




            <h2><i className="fas fa-lightbulb"></i>Suggestions</h2>
            <div className="suggestion-cards">
                {structuredAiResponse.suggestions.map((suggestion, index) => (
                    <div key={index} className="suggestion-card">
                        <p className="before-text">
                            <i className="fas fa-arrow-left before-icon"></i> {/* Before icon */}
                            Before:
                        </p>
                        <p>{suggestion.before}</p>
                        <p className="after-text">
                            <i className="fas fa-arrow-right after-icon"></i> {/* After icon */}
                            After:
                        </p>
                        <p>{suggestion.after}</p>
                    </div>
                ))}
            </div>

            <h2><i className="fas fa-question-circle"></i>Questions</h2>
            <ul className="question-list">
                {structuredAiResponse.questions.map((question, index) => (
                    <li key={index} className="question-item">
                        <i className="fas fa-question-circle question-icon"></i> {/* Question icon */}
                        {question}
                    </li>
                ))}
            </ul>
            

            
            <Footer />
        </div>
    );
};

export default DetailsPage;
