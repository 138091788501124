import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route
import Home from './pages/home'; // Import page components
import LoginSignUp from './pages/loginSignup';
import Profile from './pages/profile'; // Import Profile component
import DetailsPage from './pages/details'; 

const API_URL = process.env.REACT_APP_API_URL;

function App() {

  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const token = sessionStorage.getItem('token');

        const response = await fetch(`${API_URL}/user/session`, {
          method: 'POST', // Using POST method
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : undefined, // Include token if available
          },
          body: JSON.stringify({}), // Include body if needed
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setSessionData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSessionData();
  }, []); // Empty dependency array ensures this runs only once

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }


  return (
    <Routes> {/* Define your routes here */}
      <Route path="/" element={<Home />} />
      <Route path="/login-signup" element={<LoginSignUp />} />
      <Route path="/profile" element={<Profile />} /> {/* Route for profile */}
      <Route path="/details" element={<DetailsPage />} />

    </Routes>
  );
}

export default App;
