import React, { useState } from 'react';
import Header from '../components/header'; // Import the Header component
import { useLocation, useNavigate } from 'react-router-dom'; // Import necessary hooks
import { loginUser, signupUser } from '../services/authService'; // Adjust the path as needed
import { GoogleLogin } from '@react-oauth/google';


function LoginSignup() {
  const location = useLocation();
  const navigate = useNavigate();
  const { type } = location.state || {};

  const [currentView, setCurrentView] = useState(type || 'login');

  const handleToggle = (view) => {
    setCurrentView(view);
    navigate('/login-signup', { state: { type: view } }); // Update URL state
  };

  /* 
  function for login
  */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
        await loginUser(email, password);
        // Redirect to the profile page on successful login
        navigate('/profile');
    } catch (error) {
        setError(error.message || 'An unexpected error occurred'); // Handle errors
    }
};

/*
for signup
*/
  const [Signupemail, setSignupEmail] = useState('');
  const [Signuppassword, setSignupPassword] = useState('');
  const [Signuperror, SignupsetError] = useState('');
  const [fullName, setFullName] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
        await signupUser(fullName, Signupemail, Signuppassword);
        // Redirect to the profile page on successful signup
        navigate('/profile');
    } catch (error) {
        SignupsetError(error.message || 'An unexpected error occurred'); // Handle errors
    }
};


const handleGoogleSuccess = async (response) => {
  // Handle Google OAuth response
  try {
      const token = response.credential;
      // Send the token to your backend for further validation
      const result = await fetch('/api/google-login', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
      });

      if (result.ok) {
          // Redirect to the profile page on successful login
          navigate('/profile');
      } else {
          const errorData = await result.json();
          setError(errorData.message || 'Google login failed');
      }
  } catch (error) {
      setError('An unexpected error occurred'); // Handle network errors
  }
};

const handleGoogleError = (error) => {
  setError('Google login failed. Please try again.');
};

  return (
    <div className="LoginSignup">
      <Header/>
      <div className="container" id="container">

        <div className="form-container">
        {type === 'signup' ? (
          <div className="sign-up">
            <form onSubmit={handleSignup}>
              <h1>Create Account</h1>
              <div className="social-icons">
              <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                />
              </div>
              <span>or use your email to register</span>
              <input
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={Signupemail}
                    onChange={(e) => setSignupEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={Signuppassword}
                    onChange={(e) => setSignupPassword(e.target.value)}
                />
                <button type="submit">Sign Up</button>
                {Signuperror && <p className="error">{Signuperror}</p>}
            </form>
          </div>
        ) : (
          <div className="sign-in">
            <form onSubmit={handleLogin}>
              <h1>Sign In</h1>
              <div className="social-icons">
              <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                />
              </div>
              <span>or use your email and password</span>
             
              <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
         <a href="#">Forgot Password?</a>
        {error && <p className="error">{error}</p>}
        <button type="submit">Sign In</button>

            </form>
          </div>
        )}
      </div>

      <div className="toggle-container">
        <div className="toggle">
          <div 
            className={`toggle-panel toggle-left ${currentView === 'login' ? 'active' : ''}`}
            onClick={() => handleToggle('login')}
          >
            <h1>Welcome Back!</h1>
            <p>Enter your personal details to use all of site features.</p>
            <button className={`toggle-btn ${currentView === 'login' ? 'active' : ''}`} id="login">
              Sign In
            </button>
          </div>

          <div 
            className={`toggle-panel toggle-right ${currentView === 'signup' ? 'active' : ''}`}
            onClick={() => handleToggle('signup')}
          >
            <h1>Hello, Subscriber!</h1>
            <p>Register with your personal details to use all of site features.</p>
            <button className={`toggle-btn ${currentView === 'signup' ? 'active' : ''}`} id="register">
              Sign Up
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default LoginSignup;
