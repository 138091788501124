// Define the base API URL from environment variables
const API_URL = process.env.REACT_APP_API_URL;

// Define a function to handle login
export const loginUser = async (email, password) => {
    try {
        const response = await fetch(`${API_URL}/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Login failed');
        }

        const data = await response.json();
        const { token } = data.data;

        sessionStorage.setItem('token', token);

        return data;

    } catch (error) {
        throw error;
    }
};

// Define a function to handle signup
export const signupUser = async (fullName, email, password) => {
    try {
        const response = await fetch(`${API_URL}/user/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fullName, email, password }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Signup failed');
        }

        const data = await response.json();
        const { token } = data.data;

        sessionStorage.setItem('token', token);

        return data;

    } catch (error) {
        throw error;
    }
};

// Define a function to handle logout
export const logoutUser = async () => {
    try {
        // Optionally, call the API endpoint for server-side logout
        await fetch(`${API_URL}/user/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${sessionStorage.getItem('token')}`,
            },
        });

        // Clear token from session storage
        sessionStorage.removeItem('token');
    } catch (error) {
        console.error('Logout failed:', error);
    }
};
