import React, { useState, useEffect, useRef } from 'react';
import { fetchProfile } from '../services/profileService'; // Adjust the path as needed
import { logoutUser } from '../services/authService'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import logo from '../Questume-logo.png'; // Adjust the path to your logo
import { uploadResume } from '../services/resumeService'; // Add the import for the uploadResume service
import search from '../images/search.gif'; // Adjust the path to your logo
import owl from '../images/owl-img.png';
import duck from '../images/duck-img.png';
import bulb from '../images/bulb.png';
import Footer from '../components/footer'; // Import the Footer component

const Profile = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null); // Reference to the file input
    const navigate = useNavigate(); // Use navigate to redirect after logout

    useEffect(() => {
        const loadProfile = async () => {
            try {
                const data = await fetchProfile();
                setUser(data.data.profile); // Assuming data contains user information
            } catch (error) {
                setError(error.message || 'An unexpected error occurred');
            }
        };

        loadProfile();
    }, []);

    const handleLogout = async () => {
        try {
            await logoutUser();
            // Redirect to login page or another page after logout
            navigate('/login-signup');
        } catch (error) {
            setError('Failed to log out. Please try again.');
        }
    };

    const handleFileUpload = async () => {
        const fileInput = fileInputRef.current;
        if (!fileInput.files.length) {
            setError('No file selected.');
            return;
        }

        const file = fileInput.files[0];
        if (file.type !== 'application/pdf') {
            setError('Please select a valid PDF file.');
            return;
        }

        setUploading(true);
        try {
            const response = await uploadResume(file); // Ensure this function returns the expected response
            if (response.status === 'success') {
                setSuccess('File uploaded successfully.');
                setError(''); // Clear previous error if any
                fileInput.value = ''; // Clear the file input
            } else {
                setError('Failed to upload file. Please try again.');
                setSuccess(''); // Clear previous success message if any
            }
        } catch (error) {
            setError('Failed to upload file. Please try again.');
            setSuccess(''); // Clear previous success message if any
        } finally {
            setUploading(false);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click(); // Programmatically trigger file input click
    };

    return (
        <div className="profile">
            <header className="App-header">
                <img className="App-logo" src={logo} alt="App Logo" />
                <div className='btn-container'>
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </div>
            </header>
           <div className='profile-body'>
              {user ? (
                  <div className="profile-container">
                      <div className='content-section'>
                      <img src={owl} alt="owl" className='owl-img'/>
                          <p className='profile-header'>AI-Powered Resume Analyzer & Interview Prep</p>
                          <p className='profile-subheader'>Your resume, smarter than ever. Get ready for your next opportunity with AI-driven insights and interview preparation.</p>
                      </div>
                      <div className='upload-resume'>
                          <img src={search} alt="search" className='search-img'/>
                          <input
                              ref={fileInputRef} // Set ref to the file input
                              type="file"
                              accept="application/pdf"
                              style={{ display: 'none' }} // Hide the file input
                              onChange={() => handleFileUpload()} // Trigger upload when file is chosen
                          />
                          <button className='upload-cta'
                              onClick={triggerFileInput} // Trigger file input click
                              disabled={uploading}
                          >
                              {uploading ? 'Uploading...' : 'Upload Resume'}
                          </button>
                          {error && <p className="error">{error}</p>}
                          {success && <p className="success">{success}</p>}
                      </div>
                  </div>
                  
              ) : (
                  <p>Loading profile...</p>
              )}

              

              <div className='info-section'>
                <div className='each-row'>
                    <div className='features'>
                      <h5 className='section-heading-info'>Features</h5>
                      <div>
                        <p>
                        <b>AI-Powered Resume Parsing:</b><br></br>
                        Upload your resume in PDF format, and our AI will parse it to detect skills, experiences, and areas for improvement.
                        
                        </p>
                        <p><b>Interview Questions Tailored to You:</b><br></br>
                        Get a personalized list of interview questions based on your unique resume to sharpen your preparation.
                        </p>
                        <p><b>Resume Suggestions & Corrections:</b><br></br>
                        Our AI checks for mistakes, structure improvements, and content gaps in your resume, ensuring it stands out.
                        <br></br></p>
                        <p><b>Quiz Yourself:</b><br></br>
                        Stay ahead with quiz questions that reflect your resume's content, boosting your confidence and knowledge.
                        <br></br></p>
                        <p><b>Answer Hints:</b><br></br>
                        Not just questions—get insightful answers to boost your interview skills.</p>
                        
                    </div>
                    </div>
                    <img src={duck} alt="duck" className='duck-img'/>
                </div>
                <div className='each-row'>
                    
                    <img src={bulb} alt="bulb" className='bulb-img'/>
                    <div className='how-it-works'>
                      <h5 className='section-heading-info'>How It Works</h5>
                      <div>
                        <p>Step 1: Upload your resume.</p>
                          <p><br></br>Step 2: AI analyzes your resume to find key areas for improvement.</p>
                          <p><br></br>Step 3: Receive personalized interview questions and quizzes.</p>
                          <p> <br></br>Step 4: Get resume corrections and answer hints to perfect your preparation.</p>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;
