import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import App from './App'; // Import the App component
import './index.css'; // Import global styles
import { GoogleOAuthProvider } from '@react-oauth/google';


ReactDOM.render(
  <React.StrictMode>
    <Router> {/* Single Router wrapping the entire app */}
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <App />
    </GoogleOAuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
