import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import logo from '../Questume-logo.png'; // Adjust the path to your logo

function Header() {
    const navigate = useNavigate();

    const handleClick = (path, type) => {
      navigate(path, { state: { type } }); // Pass state with type information
    };

  return (
    <header className="App-header">
      <img className="App-logo" onClick={() => handleClick('/')} src={logo} alt="App Logo" />
      <div className='btn-container'>
        <button onClick={() => handleClick('/login-signup', 'login')}>Login</button>
        <button onClick={() => handleClick('/login-signup', 'signup')}>Signup</button>
      </div>
    </header>
  );
}

export default Header;
