// Define the base API URL from environment variables
const API_URL = process.env.REACT_APP_API_URL;

// Define a function to fetch user profile
export const fetchProfile = async () => {
    try {
        const token = sessionStorage.getItem('token');
        
        if (!token) {
            throw new Error('No token found');
        }

        const response = await fetch(`${API_URL}/user/profile`, {
            method: 'POST',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json', // Optional, if your API expects it
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch profile data');
        }

        const data = await response.json();
        return data; // Return data for use in the component

    } catch (error) {
        throw error; // Propagate error for handling in the component
    }
};
