import React from 'react';
import logo from '../Questume-logo.png'; // Adjust the path to your logo

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={logo} alt="App Logo" className="footer-logo" />
        <p className="footer-text">© {new Date().getFullYear()} Questume Pvt. Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
